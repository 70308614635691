<template>
  <Dialog v-model:visible="dialogActive" modal :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" dismissableMask @hide="closeDialog">
    <template #header>
      <div class="flex flex-row justify-content-between w-6">
        <label>Coût global: </label>
        <label class="font-bold">{{ `${importedPay?.jsonImportedPay?.globalCompanyCost} €` }}</label>
      </div>
    </template>
    <div class="flex flex-column w-6">
      <div class="info">
        <label>Salaire BRUT </label>
        <label class="font-bold">{{ `${importedPay?.jsonImportedPay?.brutSalary} €` }}</label>
      </div>
      <div class="info">
        <label>Cotisations sociales </label>
        <!-- Calcul here ?-->
        <label class="font-bold">{{ `${((importedPay?.jsonImportedPay?.employerCharge * 100) + (importedPay?.jsonImportedPay?.salaryExpense * 100)) / 100} €` }}</label>
      </div>
      <div class="info">
        <label>Net à payer </label>
        <label class="font-bold">{{ `${importedPay?.jsonImportedPay?.netSalary} €` }}</label>
      </div>
      <div class="info">
        <label>Revenus imposables </label>
        <label class="font-bold">{{ `${importedPay?.jsonImportedPay?.taxableRevenue} €` }}</label>
      </div>
      <div class="info">
        <label>Prélèvement à la source </label>
        <label class="font-bold">{{ `${importedPay?.jsonImportedPay?.pas} €` }}</label>
      </div>
    </div>
  </Dialog>
</template>

<script>
export default {
  name: "DialogPayDetails",
  props: {
    importedPay: {
      type: Object,
      default: () => { return {} }
    },
    isActive: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  watch: {
    isActive (value) {
      this.dialogActive = value
    }
  },
  data () {
    return {
      dialogActive: false,
    }
  },
  created () {
    this.dialogActive = this.isActive
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style scoped>
.info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
