import axios from "@/plugins/axios";
import { formatSpreadsheetFile } from "@/utils/ExportFileType";

/**
 *
 * @param exportSheetObject Contains certain keys for export properly
 * @param routeApi Controller Api to use
 * @param context Used to manage error by notifying the user
 * @return {Promise<unknown>}
 */
export default async (exportSheetObject, routeApi, context) => {
    const dtoExportSheet = {
        keys: exportSheetObject.ids ?? null,
        year: parseInt(exportSheetObject?.year) ?? null,
        month: parseInt(exportSheetObject?.month) ?? null,
        range: exportSheetObject.range
    }
    return new Promise((resolve, reject) => {
        axios.api.post(routeApi, dtoExportSheet)
            .then(async (res) => {
                await formatSpreadsheetFile(res.data.sheetBuffer.data)
                // eslint-disable-next-line no-unused-vars
                const { sheetBuffer, ...otherProps } = res.data
                resolve(otherProps)
            })
            .catch(() => {
                context.$toast.add({severity:'error', summary:'Erreur', detail:'Une erreur s\'est produite', life: 3000});
                reject(true)
            })
    })
}
