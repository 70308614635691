<template>
  <div class="non-scrollable">
    <DataTable ref="dt" :value="paies" :paginator="true" class="p-datatable-customers" :rows="constants.defaultRowNumber" selection-mode="single"
               dataKey="id" :rowHover="true" v-model:filters="filters" filterDisplay="menu" :loading="loading" sort-field="status" :sort-order="1"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="constants.rowsPerPageOptions"
               :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.portes')})"
               scrollable scrollHeight="flex" @rowSelect="clickPorte">
      <template #header>
        <div class="flex" :class="historic ? 'gap-2' : 'justify-content-between'">
          <div class="flex flex-row align-items-center gap-2">
            <span class="p-input-icon-left gap-3">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" :placeholder="$t('search')" />
            </span>
            <Button label="Forcer la déclaration de paie" @click="forcePayDeclaration"/>
          </div>
          <div class="flex flex-row gap-2">
            <SelectButton v-if="filtersSet && !historic" v-model="filterValue" :options="filtersOptions" option-value="value" option-label="label"
                          @update:modelValue="(value) => filterStatusBy(!value ? null : [value])"/>
            <Calendar v-if="filtersSet" v-model="filterDate" view="month" date-format="MM (yy)" showIcon
                      @update:modelValue="(value) => filterDateBy(!value ? null : [value.getMonth()])"/>
          </div>
          <Button v-if="!historic"
                  :disabled="paies.length === 0" :class="{ 'disabled-button': paies.length === 0 }" class="h-auto"
                  :loading="exportSheetLoading" icon="pi pi-external-link"
                  :label="`${$t('export')} ${!filterDate ? '' : 'le mois de ' + capitalizeWord(filterDate.toLocaleDateString('fr-FR', { month: 'long' }))}`"
                  @click="createSheet"/>
        </div>
      </template>
      <template #empty>
        {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
      </template>
      <template #loading>
        {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
      </template>
      <Column field="independent.last_name" :header="$t('lastname').toUpperCase()" sortable filterField="independent.last_name" sortField="independent.last_name"/>
      <Column field="independent.first_name" :header="$t('firstname').toUpperCase()" sortable filterField="independent.first_name" sortField="independent.first_name"/>
      <Column v-if="historic" field="jsonImportedPay.date" :header="$t('date').toUpperCase()" sortable filterField="jsonImportedPay.date" sortField="jsonImportedPay.date" :showFilterMatchModes="false" :show-apply-button="false">
        <template #body="{data}">
          <span class="capitalize">{{ getDateFormatted(data?.jsonImportedPay?.date) }}</span>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <Dropdown v-model="filterModel.matchMode" :options="FilterMatchModeCustomOptions()"
                    option-value="value" option-label="label" class="mb-2" @update:model-value="filterCallback()"/>
          <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" :manual-input="false"
                    :showOnFocus="false" show-icon @date-select="filterCallback()"/>
        </template>
      </Column>
      <Column v-if="historic" field="jsonImportedPay.globalCompanyCost" header="COÛT GLOBAL" sortable sortField="jsonImportedPay.globalCompanyCost">
        <template #body="{data}">
          <Button :label="`-${data?.jsonImportedPay?.globalCompanyCost} €`" class="p-button-link" @click="openPayDetails(data)"/>
        </template>
      </Column>
      <Column v-if="historic" field="jsonImportedPay.globalCompanyCost" header="RESERVE" sortable sortField="jsonImportedPay.globalCompanyCost">
        <template #body="{data}">
          <span class="capitalize">{{ `${data?.jsonImportedPay?.reserve} €` }}</span>
        </template>
      </Column>
      <Column v-if="historic" field="jsonImportedPay.globalCompanyCost" header="DECLARATION" sortable sortField="jsonImportedPay.globalCompanyCost">
        <template #body="{data}">
          <Button label="Declaration" class="p-button-link" @click="openDeclaration({ year: data?.year, month: data?.month + 1, socialSecurityCode: data?.independent?.socialSecurityCode })"/>
        </template>
      </Column>
      <Column v-if="!historic" field="status" header="ETAT" filterField="status" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
        <template #body="{data}">
          <span>{{getOptionLabel(constants.data.payOptions, data?.status)}}</span>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">Etat</div>
          <MultiSelect v-model="filterModel.value" :options="constants.data.payOptions" option-value="value" optionLabel="label"
                       :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem"
                       @change="onFilterStatusChange(filterCallback)">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.label}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="independent.status" :header="$t('status').toUpperCase()">
        <template #body="{data}">
          <Chip :class="'custom-chip-' + data.independent.status" :label="getOptionLabel(constants.data.independentOptions, data.independent.status)?.toUpperCase()"></Chip>
        </template>
      </Column>
      <Column>
        <template #body="{data}">
          <div class="flex flex-column align-items-center gap-2">
            <Button label="RELANCE" class="p-button-rounded" size="small" @click="emailIndependent(data)" />
            <div v-if="data.last_notification_date !== null" class="flex flex-row gap-1">
              <span class="text-xs">{{ `Dernière le ` }}</span>
              <span class="text-xs font-bold">{{ `${new Date(data.last_notification_date).toLocaleDateString('fr-FR')}` }}</span>
            </div>
          </div>
        </template>
      </Column>
    </DataTable>
    <dialog-file-preview
      dialog-title="Déclaration" :is-active="dialogFilePreview" :file-id="fileId"
      :document-id="documentId" delete-button replace-file-button
      @closeDialog="closeFilePreview"
    />
    <dialog-pay-details
      :is-active="dialogPayDetails"
      :imported-pay="payDetails"
      @closeDialog="closePayDetails"
    />
  </div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import Alert from '@/utils/Alert'
import {mapState} from "vuex";
import {getDateFormatted, setDateWithoutHours} from "@/utils/Date";
import DialogFilePreview from "@/components/DialogFilePreview";
import {capitalizeWord, getOptionLabel} from "@/utils/Misc";
import DialogPayDetails from "@/components/DialogPayDetails";
import {FilterMatchModeCustom, FilterMatchModeCustomOptions} from "@/utils/FiltersRegistration";
import SheetGeneratorRefactor from "@/utils/SheetGeneratorRefactor";
export default {
  name: "PaieTableauPorte",
  components: {DialogPayDetails, DialogFilePreview},
  props: {
    historic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      customers: null,
      selectedCustomers: null,
      filtersSet: false,
      filtersOptions: [],
      filterValue: null,
      filterDate: null,

      dialogFilePreview: false,
      fileId: null,
      documentId: null,

      dialogPayDetails: false,
      payDetails: {},

      filters: {
        'global': {value: null, matchMode: null},
        'independent.last_name': {value: null, matchMode: FilterMatchMode.IN},
        'independent.first_name': {value: null, matchMode: FilterMatchMode.IN},
        'jsonImportedPay.date': {value: null, matchMode: FilterMatchModeCustom.DATE_AFTER_INCLUDED},
        'status': {value: null, matchMode: FilterMatchMode.IN},
        'month': {value: null, matchMode: FilterMatchMode.IN},
        'independent.status': {value: null, matchMode: FilterMatchMode.IN},
      },
      loading: true,
      exportSheetLoading: false
    }
  },
  computed: {
    ...mapState({
      paies: state => state.pay.pays,
      constants: state => state.constants
    }),
    lastnamesFilter () {
      let array = this.paies.map((p) => p.independent?.last_name)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    firstnamesFilter () {
      let array = this.paies.map((p) => p.independent?.first_name)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    monthFilter () {
      let array = this.paies.map((p) => { return {month: p.month, year: p.year}})
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item.month)) {
          filterArray.push({
            value: item.month,
            label: new Date(item.year, item.month)
          })
        }
      })
      return filterArray
    },
  },
  async mounted () {
    await this.init()
  },
  methods: {
    async init () {
      this.loading = true
      await this.initPays()
      this.initFilters()
      process.nextTick(() => {
        this.loading = false;
      })
    },
    FilterMatchModeCustomOptions() {
      return FilterMatchModeCustomOptions
    },
    capitalizeWord,
    getOptionLabel,
    getDateFormatted,
    initFilters () {
      this.filtersSet = false
      this.filtersOptions = [
        {
          label: 'À traiter',
          value: this.constants.PAY_STATUS.TO_PROCESS,
          clicked: false
        },
        {
          label: 'En attente du porté',
          value: this.constants.PAY_STATUS.DRAFT,
          clicked: false
        },
        {
          label: 'À exporter',
          value: this.constants.PAY_STATUS.TO_EXPORT,
          clicked: false
        },
        {
          label: 'Tous',
          value: null,
          clicked: false
        }
      ]
      let filterStatus = this.getInitialFilterStatus()
      let filterDate = this.getInitialFilterDate()
      this.filterValue = filterStatus?.value
      this.filterDate = filterDate?.value
      this.filters = {
        'global': {value: null, matchMode: null},
        'independent.last_name': {value: null, matchMode: FilterMatchMode.IN},
        'independent.first_name': {value: null, matchMode: FilterMatchMode.IN},
        'jsonImportedPay.date': {value: null, matchMode: FilterMatchModeCustom.DATE_AFTER_INCLUDED},
        'status': {value: filterStatus?.values, matchMode: FilterMatchMode.IN},
        'month': {value: filterDate?.values, matchMode: FilterMatchMode.IN},
        'independent.status': {value: null, matchMode: FilterMatchMode.IN},
      }
      this.filtersSet = true
    },
    getInitialFilterStatus () {
      if (this.paies.findIndex(pay => pay.status === this.constants.PAY_STATUS.DRAFT) !== -1) {
        return { values: [this.constants.PAY_STATUS.DRAFT], value: this.constants.PAY_STATUS.DRAFT }
      } else if (this.paies.findIndex(pay => pay.status === this.constants.PAY_STATUS.TO_PROCESS) !== -1) {
        return { values: [this.constants.PAY_STATUS.TO_PROCESS], value: this.constants.PAY_STATUS.TO_PROCESS }
      } else if (this.paies.findIndex(pay => pay.status === this.constants.PAY_STATUS.TO_EXPORT) !== -1) {
        return { values: [this.constants.PAY_STATUS.TO_EXPORT], value: this.constants.PAY_STATUS.TO_EXPORT }
      } else {
        return { values: null, value: null }
      }
    },
    getInitialFilterDate () {
      // todo: see if it's needed to see all the pays in the historic view, must add a clear button on the date if so
      // if (this.historic) return { values: [], value: null }
      const currentDate = new Date()
      if (currentDate.getDate() > 10 && !this.historic) {
        return { values: [currentDate.getMonth()], value: currentDate }
      } else {
        currentDate.setMonth(currentDate.getMonth() - 1)
        return { values: [currentDate.getMonth()], value: currentDate }
      }
    },
    async onFilterStatusChange (callback) {
      this.filterValue = null
      await callback()
    },
    filterStatusBy (filterValues) {
      this.filters = {
        ...this.filters,
        'status': {value: filterValues, matchMode: FilterMatchMode.IN},
      }
    },
    filterDateBy (filterValue) {
      this.filters = {
        ...this.filters,
        'month': {value: filterValue, matchMode: FilterMatchMode.IN},
      }
    },
    sortByStatus (item) {
      let sortValue = 0
      switch (item.status) {
        case this.constants.PAY_STATUS.DRAFT:
          sortValue = 1
          break
        case this.constants.PAY_STATUS.TO_PROCESS:
          sortValue = 2
          break
        case this.constants.PAY_STATUS.TO_EXPORT:
          sortValue = 3
          break
        case this.constants.PAY_STATUS.EXPORTED:
          sortValue = 4
          break
        case this.constants.PAY_STATUS.IMPORTED:
          sortValue = 5
          break
        default:
          sortValue = 6
          break
      }
      return sortValue
    },
    closeFilePreview () {
      this.fileId = null
      this.documentId = null
      this.dialogFilePreview = false
    },
    closePayDetails () {
      this.dialogPayDetails = false
    },
    openPayDetails (data) {
      if (!data?.jsonImportedPay) {
        Alert.errorMessage(this, 'payDeclarationNotImport')
        return
      }
      this.payDetails = data
      this.dialogPayDetails = true
    },
    async openDeclaration (payload) {
      const independentDocDeclaration = await this.$store.dispatch('pay/getDeclaration', payload)
      if (!independentDocDeclaration?.document?.id) {
        Alert.errorMessage(this, 'paySlipDeclarationNotImport')
        return
      }
      this.fileId = independentDocDeclaration.document.id
      this.documentId = independentDocDeclaration.id
      this.dialogFilePreview= true
    },
    async initPays () {
      const status = []
      if (this.historic) {
        status.push(...['imported'])
      }
      await this.$store.dispatch('pay/getAllPays', { status })
    },
    clickPorte (porte) {
      process.nextTick(async () => {
        const independentsIds = (await this.$refs.dt.processedData).map((pay) => pay.independent.id)
        // todo: pas besoin de l'ajouter partout, à revoir lors de la refacto des tableaux en requêtant à chaque filtre et à chaque changement en changeant les keys à afficher directement après la requête
        this.$store.dispatch('sidebar/updateIndependentIdsFilter', independentsIds)
        this.$router.push({ name: 'DeclarePaieAdmin', query: { idIndependent: porte.data.independent.id, year: porte.data.year, month: porte.data.month }})
      })
    },
    async forcePayDeclaration () {
      await this.$store.dispatch('pay/forcePaysDeclaration')
      await this.init()
      Alert.successMessage(this, 'forcePaysDeclaration')
    },
    emailIndependent (invoiceDeclaration) {
      this.$confirm.require({
        header: 'Relance',
        message: 'Êtes-vous sûr de vouloir relancer ce porté pour cette déclaration de paie',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          this.$store.dispatch('pay/notifyIndependent', {
            independentId: invoiceDeclaration.independent.id,
            payDeclarationId: invoiceDeclaration.id
          })
          this.$confirm.close()
        },
        reject: () => {
          this.$confirm.close()
        }
      })
    },
    async createSheet () {
      this.exportSheetLoading = true
      // todo: should send the date of the export, then try to get all the independent that have mission on the current month and see if the number of missions matches the number of CRAS associated with pays (TO_EXPORT)
      // .filter(pay => pay.status === this.constants.PAY_STATUS.TO_EXPORT)
      let ids = null
      if (this.filters.status.value?.length === 1 && this.filters.status.value[0] === this.constants.PAY_STATUS.TO_EXPORT) {
        ids = await this.$refs.dt.processedData.map(pay => pay.id)
      }
      const selectedDate = setDateWithoutHours(this.filterDate)?.split('-')
      SheetGeneratorRefactor({ ids, year: selectedDate[0], month: selectedDate[1] }, '/pay/export-sheet', this)
          .then((data) => {
            this.$store.dispatch('pay/replaceSomePays', data.paysExported)
            this.exportSheetLoading = false
          })
          .catch(() => {
            this.exportSheetLoading = false
          })
    }
  }
}
</script>
